<template>
  <v-card>
    <v-alert
      :value="exportDisable"
      colored-border
      dismissible
      color="primary lighten-1"
      border="top"
      icon="mdi-check"
      transition="scale-transition"
      elevation="2"
      prominent
    >
      🤾🏻‍♀️ 🤸🏻‍♂️ 🏌🏻‍♀️ 🤾🏻‍♂️ Success.
    </v-alert>
    <v-card-title> Assign Survey </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="surveyData.surveyName" 
          label="Survey Name" 
          required
          persistent-hint
          hint="Name of the survey"
          :rules="[v => !!v || 'Survey Name is required']"
          >
        </v-text-field>
        <v-autocomplete
          :items="projects"
          item-text="name"
          item-value="_id"
          label="Select Project"
          v-model="surveyData.projectId"
          :rules="[v => !!v || 'Project is required']"
        >
        </v-autocomplete>
        <v-text-field 
          v-model="surveyData.surveyUrl" 
          label="Survey URL" 
          required
           persistent-hint
          :rules="urlRules"
          >
        </v-text-field>
        <v-text-field 
          v-model="surveyData.campaignName" 
          label="Campaign Name" 
          required
          persistent-hint
          hint="Any Random Name"
          :rules="[v => !!v || 'Campaign Name is required']"
          >
        </v-text-field>
        <v-text-field 
          v-model="surveyData.payout" 
          label="Points to be assigned (100 points = 1 Usd)" 
          required
          persistent-hint
          hint="Amount earner by user upon completing survey"
          :rules="[v => !!v || 'Points are required']"
          >
        </v-text-field>


        <v-btn 
          class="mt-5"
          @click="submit()"
          color="primary"
        >
          Submit
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>


<script>
import RestResource from "@/services/dataServiceBuyer.js";
const service = new RestResource();

import RestResourceMobile from "@/services/dataServiceMobile.js";
const mobileService = new RestResourceMobile();


export default {
  data() {
    return {
      projects: [],
      surveyData: {},
      valid: true,
      exportDisable: false,
      urlRules: [
        v => !!v || "Url must be valid",
        // eslint-disable-next-line
        v => /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(v) || "Url must be valid"
      ],
    };
  },
  mounted() {
    this.loadProjects()
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        mobileService.assignSurvery(this.surveyData)
        this.exportDisable = true
      }
    },

    loadProjects() {
      this.$setLoader();
      service.fetchProjects({
        selectFields: "_id name",
        sort: { created_at: -1 }
      }).then(r => {
        this.projects = r.data;
        this.$disableLoader();
      });
    },
  }
};
</script>